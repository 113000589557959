import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FaNewspaper } from "react-icons/fa"
import { MdVideoLibrary } from "react-icons/md"

export default () => (
  <StaticQuery
    query={graphql`
      query ActualitesHome {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "actus" } } }
          limit: 3
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "DD/MM/YYYY")
                excerpt
                image {
                  childImageSharp {
                    fluid(maxWidth: 813) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
                imageAlt
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <H2>
          <span>Actualités</span> & Vidéos
        </H2>
        <hr />
        {data.allMarkdownRemark.edges.map(({ node }) => {
          const { title, excerpt, date, image, imageAlt } = node.frontmatter
          const { slug } = node.fields
          return (
            <Row>
              <Col1>
                <Link
                  to={slug}
                  css={css`
                  h3 {opacity: 1;}
                  .gatsby-image-wrapper img {
                    transition: all 0.7s !important;
                    transform: scale(1) !important;
                  }
                  :hover {
                    h3 {opacity: .7;}
                    transition: all 0.2s;
                    .gatsby-image-wrapper img {
                      transition: all 0.7s !important;
                      transform: scale(1.05) !important;
                    }
                    @media (max-width: 678px) {
                      &:hover {
                        box-shadow: none;
                        .gatsby-image-wrapper img {
                          transform: scale(1) !important;
                        }
                      }
                  }
                  `}
                >
                  <Img
                    fluid={image.childImageSharp.fluid}
                    css={css`
                      width: 100%;
                      margin-right: 25px;
                      border-radius: 5px;
                      max-width: 300px;
                    `}
                    alt={imageAlt}
                  />
                </Link>
              </Col1>

              <Col2>
                <Link
                  to={slug}
                  css={css`
                    text-decoration: none;
                  `}
                >
                  <H3>{title}</H3>
                  <Date>{date}</Date>
                  <p>{excerpt}</p>
                  <Suite>Lire la suite</Suite>
                </Link>
              </Col2>
            </Row>
          )
        })}
        <div
          css={css`
            display: flex;
            flex-direction: row-reverse;
            margin-top: 10px;
            @media (max-width: 678px) {
              display: block;
            }
          `}
        >
          <Button to="/actus/">
            <FaNewspaper
              css={css`
                transform: translateY(2px);
                margin-right: 4px;
              `}
            />
            Lire les actus
          </Button>
          <ButtonInverse to="/videos/">
            <MdVideoLibrary
              css={css`
                transform: translateY(2px);
                margin-right: 4px;
              `}
            />
            Voir les vidéos
          </ButtonInverse>
        </div>
      </Wrapper>
    )}
  />
)

const Wrapper = styled.section`
  background-color: white;
  border-radius: 5px;
  margin: 2.5em auto 0.5em;
  padding: 1.5em 1.5em 1em 1.5em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    padding: 1em;
  }
`
const H2 = styled.h2`
  text-align: center;
  padding: 0.25em 0 0;
  font-weight: 400;
  font-size: 2.4em;
  margin-block-start: 0;
  span {
    font-weight: 800;
  }
  @media (max-width: 678px) {
    font-size: 2.1em;
  }
`

const Button = styled(Link)`
  display: inline-block;
  margin-left: 20px;
  width: 175px;
  text-align: center;
  text-decoration: none;
  margin-top: 15px;
  padding: 0.4em 0.8em 0.5em;
  background-color: #d69c31;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 0.94em;
  letter-spacing: 0.035em;
  border-radius: 4px;
  transition: all 0.15s ease-in-out;
  :hover,
  :focus {
    color: white;
    background-color: rgba(44, 44, 44, 0.96);
    outline: none;
    transition: all 0.15s ease-in-out;
  }
  @media (max-width: 678px) {
    display: block;
    margin: 5px auto 0;
  }
`

const ButtonInverse = styled(Link)`
  display: inline-block;
  margin-left: 20px;
  width: 175px;
  text-align: center;
  text-decoration: none;
  margin-top: 15px;
  padding: 0.4em 0.8em 0.5em;
  background-color: rgba(44, 44, 44, 0.96);
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 0.94em;
  letter-spacing: 0.035em;
  border-radius: 4px;
  transition: all 0.15s ease-in-out;
  border: 1px solid black;
  :hover,
  :focus {
    color: black;
    background-color: white;
    outline: none;
    transition: all 0.15s ease-in-out;
  }
  @media (max-width: 678px) {
    display: block;
    margin: 15px auto 5px;
  }
`

const Row = styled.article`
  display: flex;
  margin: 40px 0 25px;
  @media (max-width: 678px) {
    display: block;
  }
`

const Col1 = styled.section`
  flex: 27%;
  padding-right: 15px;
  padding-top: 5px;
`

const Col2 = styled.section`
  flex: 72%;
  padding-left: 15px;
  @media (max-width: 678px) {
    padding-left: 0;
  }
`

const H3 = styled.h3`
  display: inline-block;
  margin: 0.4em 0 0;
  font-size: 1.4em;
  :hover {
    color: #d69c31;
  }
`

const Date = styled.div`
  font-size: 13px;
  opacity: 0.82;
  font-weight: 400;
  margin-bottom: -4px;
  letter-spacing: 0.03em;
  @media (max-width: 678px) {
    margin-top: 2px;
    margin-bottom: -3px;
  }
`

const Suite = styled.p`
  font-size: 13px;
  opacity: 0.82;
  text-align: right;
  margin-right: 20px;
  font-weight: 400;
  margin-top: -5px;

  :hover {
    color: #d69c31;
  }
  @media (max-width: 678px) {
  }
`
